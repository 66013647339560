import { MetaFunction, LinksFunction } from 'remix';

import { TwoColumn, Introduction, Recent } from '~/components';
import styles from '~/styles/routes/home.css';

export const links: LinksFunction = () => {
  return [{ rel: 'stylesheet', href: styles }];
};

export const meta: MetaFunction = () => {
  return {
    title: 'Home | @GhosteTheDev',
    description: 'All things created by Ghoste and where to find him!',
  };
};

export default function Home() {
  return (
    <TwoColumn>
      <Introduction />
      <Recent />
    </TwoColumn>
  );
}
