import { Link } from 'remix';
import { generalLinks, socials } from '~/config';

export const Introduction = () => {
  return (
    <div className="col-span-2">
      <h1 className="text-primary text-4xl">
        Hi there
        <span
          role="img"
          className="inline-block animate-wiggle ml-4 text-4xl"
          aria-labelledby="waving hand"
        >
          👋
        </span>
      </h1>
      <div className="space-y-5 mt-7">
        <p className="max-w-prose">
          My name is Mitchel but you might now me by my{' '}
          <a
            href={socials.TWITTER_URL}
            target="_blank"
            rel="noreferrer"
            className="underline"
          >
            twitter handle - GhosteTheDev.
          </a>{' '}
          I'm currently building awesome next-gen e-commerce solutions at{' '}
          <a
            href={generalLinks.AMS_URL}
            target="_blank"
            rel="noreferrer"
            className="underline"
          >
            Story of AMS
          </a>
          . Be sure to check out some of{' '}
          <Link to="projects" className="underline">
            the awesome projects
          </Link>{' '}
          I have had the pleasure working on!
        </p>
        <p className="max-w-prose">
          The reason I've build this website is, to be totally honest,{' '}
          <a
            href="https://remix.run/"
            target="_blank"
            rel="noreferrer"
            className="underline"
          >
            remix.run
          </a>{' '}
          <span role="img" aria-label="fire">
            🔥
          </span>{' '}
          It isn't the first version of a portfolio that I've been trying to get
          online but I believe I've finally found the right version for me to
          release to the public. You can find more on the hows and why I've
          build this website in{' '}
          <Link to="blog/i-finally-have-a-blog" className="underline">
            this blog post
          </Link>
        </p>
        <p className="max-w-prose">
          If you made it this far that means you're dedicated and I like it! It
          also means you will probably find some 'useful' stuff somewhere in one
          of{' '}
          <Link to="blog" className="underline">
            my blog articles
          </Link>
          , please let me know if you do. I'm pretty active on twitter if that
          is your type of thing but I've also made sure{' '}
          <Link to="contact" className="underline">
            the contact form
          </Link>{' '}
          on this site actually works{' '}
          <span role="img" aria-label="grin">
            😁
          </span>
        </p>
        <p className="max-w-prose">
          This website is always under construction and can change without
          notice. I have a lot of plans, time not so much, something we can
          probably all relate to. So no promises at this time, but make sure to
          check out my twitter stay up to date on the latest and greatest.
          Enough has been said (perhaps even too much already) so go forth and
          click around{' '}
          <span role="img" aria-label="vulcan-salute">
            🖖
          </span>
        </p>
      </div>
    </div>
  );
};
