import { socials } from '~/config';

type ConnectProps = {};

export const Connect = ({ ...props }: ConnectProps) => {
  return (
    <div className="flex flex-col items-end">
      <h4 className="text-primary uppercase tracking-widest">
        Quickly connect
      </h4>
      <div className="flex flex-col space-y-1 mt-1 items-end">
        <a href={socials.TWITTER_URL} target="_blank" rel="noreferrer">
          Twitter
        </a>
        <a href={socials.LINKEDIN_URL} target="_blank" rel="noreferrer">
          LinkedIn
        </a>
        <a href={socials.GITHUB_URL} target="_blank" rel="noreferrer">
          Github
        </a>
      </div>
    </div>
  );
};
