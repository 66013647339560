type TwoColumnProps = {
  space?: number;
  children: React.ReactNode;
};

export const TwoColumn = ({ children, space = 10 }: TwoColumnProps) => {
  return (
    <div className={`grid grid-cols-1 gap-${space} md:grid-cols-3`}>
      {children}
    </div>
  );
};
