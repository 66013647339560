import { Stacked } from '~/components/layouts/Stacked';

import { Connect } from './Connect';

type RecentProps = {};

export const Recent = ({ ...props }: RecentProps) => {
  return (
    <Stacked space={10}>
      <div>
        <h3> Recent articles </h3>
        <div className="h-24 w-80 bg-secondary opacity-40 rounded-lg mt-1" />
      </div>
      <div>
        <h3> Recent links </h3>
        <div className="h-24 w-80 bg-secondary opacity-40 rounded-lg mt-1" />
      </div>
      <Connect />
    </Stacked>
  );
};
